import React from 'react';
import { Tabs, Tab, Toolbar, AppBar, Box, Typography } from '@mui/material';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { UserMenu, Logout, LoadingIndicator } from 'react-admin';

const Header = () => {
  const location = useLocation();

  let currentPath = '/';
  if (!!matchPath('/users/*', location.pathname)) {
    currentPath = '/users';
  } else if (!!matchPath('/parties/*', location.pathname)) {
    currentPath = '/parties';
  } else if (!!matchPath('/deals/*', location.pathname)) {
    currentPath = '/deals';
  }

  return (
    <Box component="nav" sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="default">
        <Toolbar variant="dense">
          <Box flex={1} display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box
                component="img"
                sx={{ marginRight: '1em', height: 50 }}
                src={
                  '/logos/invite.png'
                }
                alt="Bosch Logo"
              />
              <Typography component="span" variant="h5">
                Invite Backoffice
              </Typography>
            </Box>
            <Box>
              <Tabs
                value={currentPath}
                aria-label="Navigation Tabs"
                indicatorColor="secondary"
                textColor="inherit"
              >
                {/*<Tab*/}
                {/*    label={'דשבורד'}*/}
                {/*    component={Link}*/}
                {/*    to="/"*/}
                {/*    value="/"*/}
                {/*/>*/}
                <Tab
                  label={'לקוחות'}
                  component={Link}
                  to="/users"
                  value="/users"
                />
                <Tab
                  label={'אירועים'}
                  component={Link}
                  to="/parties"
                  value="/parties"
                />
                {/*<Tab*/}
                {/*    label={'הגדרות'}*/}
                {/*    component={Link}*/}
                {/*    to="/deals"*/}
                {/*    value="/deals"*/}
                {/*/>*/}
              </Tabs>
            </Box>
            <Box display="flex" alignItems="center">
              <LoadingIndicator />
              <UserMenu>
                <Logout />
              </UserMenu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
