import * as React from 'react';
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  SelectInput,
  required,
  email,
  useCreate,
  useGetIdentity,
  useNotify,
} from 'react-admin';
import { Divider, Box, Stack } from '@mui/material';

import { genders } from './constants';

const isUrl = (value: string) => {
  if (!value) return;
  try {
    new URL(value);
  } catch (_) {
    return 'Must be a valid URL';
  }
};

export const UserInputs = () => {
  const [create] = useCreate();
  const { identity } = useGetIdentity();
  const notify = useNotify();
  const handleCreateParty = async (name?: string) => {
    if (!name) return;
    try {
      const newParty = await create(
        'parties',
        {
          data: {
            name,
            sales_id: identity?.id,
            created_at: new Date().toISOString(),
          },
        },
        { returnPromise: true },
      );
      return newParty;
    } catch (error) {
      notify('An error occurred while creating the party', {
        type: 'error',
      });
      throw error;
    }
  };
  return (
    <Box flex="1" mt={-1}>
      <Stack direction="row" width={430} gap={1}>
        <TextInput
          source="first_name"
          validate={required()}
          helperText={false}
        />
        <TextInput
          source="last_name"
          validate={required()}
          helperText={false}
        />
      </Stack>
      <Stack direction="row" width={430} gap={1}>
        <TextInput source="title" helperText={false} />
        <ReferenceInput source="party_id" reference="parties">
          <AutocompleteInput
            optionText="name"
            validate={required()}
            onCreate={handleCreateParty}
            helperText={false}
          />
        </ReferenceInput>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Box width={430}>
        <TextInput
          source="email"
          helperText={false}
          validate={email()}
        />
        <Stack direction="row" gap={1}>
          <TextInput source="phone_number1" helperText={false} />
          <TextInput source="phone_number2" helperText={false} />
        </Stack>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box width={430}>
        <TextInput source="background" multiline helperText={false} />
        <TextInput
          source="avatar"
          label="Avatar URL"
          helperText={false}
          validate={isUrl}
        />
        <Stack direction="row" gap={1} alignItems="center">
          <SelectInput
            source="gender"
            choices={genders}
            helperText={false}
          />
          <BooleanInput
            source="has_newsletter"
            sx={{
              width: '100%',
              label: { justifyContent: 'center' },
            }}
            helperText={false}
          />
        </Stack>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box width={430}>
        <ReferenceInput
          reference="sales"
          source="sales_id"
          sort={{ field: 'last_name', order: 'ASC' }}
        >
          <SelectInput
            helperText={false}
            label="Account manager"
            sx={{ width: 210 }}
          />
        </ReferenceInput>
      </Box>
    </Box>
  );
};
