import * as React from 'react';
import { useState } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import UsersIcon from '@mui/icons-material/AccountCircle';
import ClockIcon from '@mui/icons-material/AccessTime';
import { AddCircle, AddCircleOutline, AddCircleOutlined } from '@mui/icons-material';

import DealIcon from '@mui/icons-material/MonetizationOn';
import {
  useCreatePath,
  SelectField,
  useRecordContext,
  Link,
} from 'react-admin';

import { sectors } from './sectors';
import { PartyAvatar } from './PartyAvatar';
import { Party } from '../types';
import moment from 'moment';

const statuses = [
  { id: 'active', name: 'פעיל', color: 'green' },
  { id: 'done', name: 'הסתיים', color: 'gray' },
  { id: 'onhold', name: 'בהולד', color: 'orange' },
  { id: 'pending', name: 'ממתין לאישור', color: 'blue' },
];

export const PartyCard = (props: { record?: Party }) => {
  const [elevation, setElevation] = useState(1);
  const createPath = useCreatePath();
  const record = useRecordContext<Party>(props);
  if (!record) return null;

  return (
    <Link
      to={createPath({
        resource: 'parties',
        id: record.id,
        type: 'show',
      })}
      underline="none"
      onMouseEnter={() => setElevation(3)}
      onMouseLeave={() => setElevation(1)}
    >
      <Paper
        sx={{
          height: 250,
          width: 300,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '1em',
          position: 'relative',
        }}
        elevation={elevation}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography fontSize={12} className={'absolute left-2 top-0 font-[9px]'}>
            #{record.id}
          </Typography>
          <PartyAvatar />
          <Box textAlign="center" marginTop={1}>
            <Typography className={'h-9'} variant="subtitle1">
              {record.title}
            </Typography>
            <Typography fontWeight={'bold'} fontSize={12} color={statuses.find(s => s.id === record.status)?.color}>
              {statuses.find(s => s.id === record.status)?.name}
            </Typography>
            <Typography variant="subtitle2">
              {moment(record.createdAt).format('DD/MM/YYYY HH:mm')} <span
              className={'font-bold text-gray-600'}> נוצר ב</span>
            </Typography>
            <Typography variant="subtitle2">
              {moment(record.date).format('DD/MM/YYYY HH:mm')} <ClockIcon sx={{ fontSize: 14 }} />
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-around" marginTop={1} width="100%">
          <Box display="flex" alignItems="center">
            <UsersIcon color="disabled" sx={{ mr: 1 }} />
            <div>
              <Typography variant="subtitle2" sx={{ mb: -1 }}>
                {record.totalTickets}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                כרטיסים
              </Typography>
            </div>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DealIcon color="disabled" sx={{ mr: 1 }} />
            <div>
              <Typography variant="subtitle2" sx={{ mb: -1 }}>
                {record.revenue}₪
              </Typography>
              <Typography variant="caption" color="textSecondary">
                רווח נטו
              </Typography>
            </div>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DealIcon color="disabled" sx={{ mr: 1 }} />
            <div>
              <Typography variant="subtitle2" sx={{ mb: -1 }}>
                {record.totalIncome}₪
              </Typography>
              <Typography variant="caption" color="textSecondary">
                הכנסות
              </Typography>
            </div>
          </Box>
        </Box>
      </Paper>
    </Link>
  );
};
