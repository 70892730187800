import * as React from 'react';
import { EditBase, Form, Toolbar, useEditContext } from 'react-admin';
import { Card, CardContent, Box } from '@mui/material';

import { Avatar } from './Avatar';
import { UserInputs } from './UserInputs';
import { UserAside } from './UserAside';
import { User } from '../types';

export const UserEdit = () => (
  <EditBase redirect="show">
    <UserEditContent />
  </EditBase>
);

const UserEditContent = () => {
  const { isPending, record } = useEditContext<User>();
  if (isPending || !record) return null;
  return (
    <Box mt={2} display="flex">
      <Box flex="1">
        <Form>
          <Card>
            <CardContent>
              <Box>
                <Box display="flex">
                  <Box mr={2}>
                    <Avatar />
                  </Box>
                  <UserInputs />
                </Box>
              </Box>
            </CardContent>
            <Toolbar />
          </Card>
        </Form>
      </Box>
      <UserAside link="show" />
    </Box>
  );
};
