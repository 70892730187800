import { AuthProvider } from 'react-admin';
import axios from 'axios';
import config from './config';

const api = axios.create({
  baseURL: config.api_url,
});


export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const rt = await api.post('login', { phone: username, otp: password });

    if (rt.status !== 200) {
      Promise.reject();
    }
    localStorage.setItem('id', rt.data.user.id);
    localStorage.setItem('name', `${rt.data.user.firstName} ${rt.data.user.lastName}`);
    localStorage.setItem('token', rt.data.accessToken);

    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
  getPermissions: () => Promise.resolve([]),
  getIdentity: () =>
    Promise.resolve({
      id: localStorage.getItem('id') ?? '',
      fullName: localStorage.getItem('name') ?? '',
    }),
};
