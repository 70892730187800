import fakeRestDataProvider from 'ra-data-fakerest';
import { fetchUtils, withLifecycleCallbacks } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import config from './config';

const httpClient = (url: any, options: any) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token') ?? '';
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

import generateData from './dataGenerator';

const baseDataProvider = simpleRestProvider(config.api_url, httpClient);
// const baseDataProvider = fakeRestDataProvider(generateData(), true, 300);

const TASK_MARKED_AS_DONE = 'TASK_MARKED_AS_DONE';
const TASK_MARKED_AS_UNDONE = 'TASK_MARKED_AS_UNDONE';
const TASK_DONE_NOT_CHANGED = 'TASK_DONE_NOT_CHANGED';
let taskUpdateType = TASK_DONE_NOT_CHANGED;

export const dataProvider = withLifecycleCallbacks(baseDataProvider, [
  {
    resource: 'userNotes',
    afterCreate: async (result, dataProvider) => {
      // update the notes count in the related user
      const { user_id } = result.data;
      const { data: user } = await dataProvider.getOne('users', {
        id: user_id,
      });
      await dataProvider.update('users', {
        id: user_id,
        data: {
          nb_notes: (user.nb_notes ?? 0) + 1,
        },
        previousData: user,
      });
      return result;
    },
    afterDelete: async (result, dataProvider) => {
      // update the notes count in the related user
      const { user_id } = result.data;
      const { data: user } = await dataProvider.getOne('users', {
        id: user_id,
      });
      await dataProvider.update('users', {
        id: user_id,
        data: {
          nb_notes: (user.nb_notes ?? 0) - 1,
        },
        previousData: user,
      });
      return result;
    },
  },
  {
    resource: 'tasks',
    afterCreate: async (result, dataProvider) => {
      // update the task count in the related user
      const { user_id } = result.data;
      const { data: user } = await dataProvider.getOne('users', {
        id: user_id,
      });
      await dataProvider.update('users', {
        id: user_id,
        data: {
          nb_tasks: (user.nb_tasks ?? 0) + 1,
        },
        previousData: user,
      });
      return result;
    },
    beforeUpdate: async params => {
      const { data, previousData } = params;
      if (previousData.done_date !== data.done_date) {
        taskUpdateType = data.done_date
          ? TASK_MARKED_AS_DONE
          : TASK_MARKED_AS_UNDONE;
      } else {
        taskUpdateType = TASK_DONE_NOT_CHANGED;
      }
      return params;
    },
    afterUpdate: async (result, dataProvider) => {
      // update the user: if the task is done, decrement the nb tasks, otherwise increment it
      const { user_id } = result.data;
      const { data: user } = await dataProvider.getOne('users', {
        id: user_id,
      });
      if (taskUpdateType !== TASK_DONE_NOT_CHANGED) {
        await dataProvider.update('users', {
          id: user_id,
          data: {
            nb_tasks:
              taskUpdateType === TASK_MARKED_AS_DONE
                ? (user.nb_tasks ?? 0) - 1
                : (user.nb_tasks ?? 0) + 1,
          },
          previousData: user,
        });
      }
      return result;
    },
    afterDelete: async (result, dataProvider) => {
      // update the task count in the related user
      const { user_id } = result.data;
      const { data: user } = await dataProvider.getOne('users', {
        id: user_id,
      });
      await dataProvider.update('users', {
        id: user_id,
        data: {
          nb_tasks: (user.nb_tasks ?? 0) - 1,
        },
        previousData: user,
      });
      return result;
    },
  },
]);
