import * as React from 'react';
import { Edit, Form, Toolbar } from 'react-admin';
import { Box, CardContent, Stack, Avatar } from '@mui/material';

import { PartyForm } from './PartyForm';

import { PartyAside } from './PartyAside';
import { LogoField } from './LogoField';

export const PartyEdit = () => (
  <Edit aside={<PartyAside link="show" />} actions={false} redirect="show">
    <Form>
      <CardContent>
        <Stack direction="row">
          <Avatar sx={{ mt: 1 }}>
            <LogoField />
          </Avatar>
          <Box ml={2} flex="1" maxWidth={796}>
            <PartyForm />
          </Box>
        </Stack>
      </CardContent>
      <Toolbar />
    </Form>
  </Edit>
);
