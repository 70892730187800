import * as React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import { useRecordContext } from 'react-admin';

import { User } from '../types';

export const Avatar = (props: { record?: User }) => {
  const record = useRecordContext<User>(props);
  if (!record) return null;

  return (
    <MuiAvatar src={record.avatar}>
      {record.first_name.charAt(0)}
      {record.last_name.charAt(0)}
    </MuiAvatar>
  );
};
