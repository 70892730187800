/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import {
  BulkActionsToolbar,
  BulkDeleteButton,
  RecordContextProvider,
  ReferenceField, ReferenceManyField,
  SimpleListLoading,
  TextField,
  useListContext,
} from 'react-admin';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  Typography,
  useMediaQuery,
} from '@mui/material';
import type { Theme } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';

import { Avatar } from './Avatar';
import { Status } from '../misc/Status';
import { PartiesList } from './PartiesList';
import { User } from '../types';
import 'moment/locale/he';
// @ts-ignore
import moment from 'moment/min/moment-with-locales';
import ClockIcon from '@mui/icons-material/AccessTime';

moment.locale('he'); // 'he' for Hebrew

export const UserListContent = () => {
  const {
    data: users,
    error,
    isPending,
    onToggleItem,
    selectedIds,
  } = useListContext<User>();
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  if (isPending) {
    return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
  }
  if (error) {
    return null;
  }
  const now = Date.now();

  return (
    <>
      {/*<BulkActionsToolbar>*/}
      {/*    <BulkDeleteButton />*/}
      {/*</BulkActionsToolbar>*/}
      <List dense>
        {users.map(user => (
          <RecordContextProvider key={user.id} value={user}>
            <ListItem
              button
              component={Link}
              to={`/users/${user.id}/show`}
            >
              <ListItemIcon sx={{ minWidth: '2.5em' }}>
                {/*<Checkbox*/}
                {/*    edge="start"*/}
                {/*    checked={selectedIds.includes(user.id)}*/}
                {/*    tabIndex={-1}*/}
                {/*    disableRipple*/}
                {/*    onClick={e => {*/}
                {/*        e.stopPropagation();*/}
                {/*        onToggleItem(user.id);*/}
                {/*    }}*/}
                {/*/>*/}
              </ListItemIcon>
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
              <ListItemText
                primary={`${user.first_name} ${user.last_name}`}
                secondary={
                  <>
                    <ReferenceManyField
                      target="creator_id"
                      reference="parties"
                    >
                      <TextField source="title" />
                    </ReferenceManyField>
                  </>
                }
              />
              <ListItemSecondaryAction
                sx={{
                  top: '10px',
                  transform: 'none',
                }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  {!isSmall && 'התחברו לאחרונה'} {' '}
                  {moment(user.last_seen).fromNow()}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          </RecordContextProvider>
        ))}
      </List>
    </>
  );
};
