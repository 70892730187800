import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Box } from '@mui/material';

import { Party } from '../types';

const sizeInPixel = {
  medium: 340,
  small: 120,
};

export const LogoField = ({
                            size = 'medium',
                          }: {
  size?: 'small' | 'medium';
}) => {
  const record = useRecordContext<Party>();
  if (!record || !record.logo) return null;
  return (
    <Box
      component="img"
      src={record.logo}
      alt={record.title}
      title={record.title}
      width={sizeInPixel[size]}
      height={sizeInPixel[size]}
      sx={{ objectFit: 'contain' }}
    />
  );
};
