export const extractDescription = (description: string) => {

  ///"[{\"type\":\"paragaph\",\"children\":[{\"text\":\"כדגכדגכדגכ\",\"color\":\"#fff\"}]}]"///
  try {
    const root = JSON.parse(description);
    return root.reduce((result: string, node: any) => {
      const childTexts = node.children.reduce((childResult: string, child: any) => {
        return childResult + ' ' + (child.text || '');
      }, '').trim();

      return result + '\n' + childTexts;
    }, '').trim();

  } catch (e) {
    return null;
  }
};