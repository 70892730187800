import * as React from 'react';
import {
  TextField,
  EmailField,
  DateField,
  ReferenceManyField,
  EditButton,
  ShowButton,
  ReferenceField,
  SelectField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { Box, Typography, Divider } from '@mui/material';
import { TagsListEdit } from './TagsListEdit';
import { AddTask } from '../tasks/AddTask';
import { TasksIterator } from '../tasks/TasksIterator';

import { User, Sale } from '../types';
import { genders } from './constants';

export const UserAside = ({ link = 'edit' }: { link?: 'edit' | 'show' }) => {
  const record = useRecordContext<User>();
  if (!record) return null;
  return (
    <Box ml={4} width={250} minWidth={250}>
      <Box mb={2} ml="-5px">
        {link === 'edit' ? (
          <EditButton label="Edit User" />
        ) : (
          <ShowButton label="Show User" />
        )}
      </Box>
      <Typography variant="subtitle2">פרטים אישיים</Typography>
      <Divider />
      <EmailField sx={{ mt: 2, display: 'block' }} source="email" />
      {record.phone && (
        <Box>
          <TextField source="phone" />{' '}
          <Typography
            variant="body2"
            color="textSecondary"
            component="span"
          >
            נייד
          </Typography>
        </Box>
      )}
      <SelectField source="gender" choices={genders} />
    </Box>
  );
};
