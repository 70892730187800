import * as React from 'react';
import { Avatar } from '@mui/material';
import { useRecordContext } from 'react-admin';

import { Party } from '../types';

export const PartyAvatar = (props: {
  record?: Party;
  size?: 'small' | 'large';
}) => {
  const { size = 'large' } = props;
  const record = useRecordContext<Party>(props);
  if (!record) return null;
  return (
    <Avatar
      src={record.logo}
      alt={record.title}
      sx={{
        bgcolor: 'aliceblue',
        '& img': { objectFit: 'cover' },
        height: 72,
        width: 72,
      }}
      imgProps={{ className: 'large' }}
    />
  );
};
