/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import {
  CreateButton,
  downloadCSV,
  ExportButton,
  List as RaList,
  Pagination,
  SortButton,
  TopToolbar,
  useGetIdentity,
} from 'react-admin';
import type { Exporter } from 'react-admin';
import jsonExport from 'jsonexport/dist';

import { UserListFilter } from './UserListFilter';
import { UserListContent } from './UserListContent';
import { User, Party, Sale } from '../types';

export const UserList = () => {
  const { identity } = useGetIdentity();
  if (!identity) return null;
  return (
    <RaList<User>
      actions={<> </>}
      aside={<UserListFilter />}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      sort={{ field: 'last_seen', order: 'DESC' }}
      exporter={exporter}
      className={'mt-12'}
    >
      <UserListContent />
    </RaList>
  );
};

const UserListActions = () => (
  <TopToolbar>
    <SortButton fields={['last_name', 'first_name', 'last_seen']} />
    <ExportButton />
    <CreateButton
      variant="contained"
      label="New User"
      sx={{ marginLeft: 2 }}
    />
  </TopToolbar>
);

const exporter: Exporter<User> = async (records, fetchRelatedRecords) => {
  const parties = await fetchRelatedRecords<Party>(
    records,
    'party_id',
    'parties',
  );
  const sales = await fetchRelatedRecords<Sale>(records, 'sales_id', 'sales');

  const users = records.map(user => ({
    ...user,
    sales: `${sales[user.sales_id].first_name} ${
      sales[user.sales_id].last_name
    }`,
  }));
  return jsonExport(users, {}, (_err: any, csv: string) => {
    downloadCSV(csv, 'users');
  });
};
