/* eslint-disable import/no-anonymous-default-export */
import { UserShow } from './UserShow';
import { UserList } from './UserList';
import { UserEdit } from './UserEdit';
import { UserCreate } from './UserCreate';
import { User } from '../types';

export default {
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
  recordRepresentation: (record: User) =>
    record?.first_name + ' ' + record?.last_name,
};
