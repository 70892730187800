/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import {
  FilterList,
  FilterLiveSearch,
  FilterListItem,
  useGetIdentity,
} from 'react-admin';
import { Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import { sizes } from './sizes';
import { sectors } from './sectors';

export const PartyListFilter = () => {
  const { identity } = useGetIdentity();
  return (
    <Box width="13em" minWidth="13em" order={-1} mr={2} mt={5}>
      <FilterLiveSearch hiddenLabel />

      <FilterList label="סטטוס" icon={<PeopleIcon />}>
        {sizes.map(size => (
          <FilterListItem
            key={size.id}
            label={size.name}
            value={{ status: size.id }}
          />
        ))}
      </FilterList>

      {/*<FilterList label="Sector" icon={<LocalShippingIcon />}>*/}
      {/*    {sectors.map(sector => (*/}
      {/*        <FilterListItem*/}
      {/*            key={sector.id}*/}
      {/*            label={sector.name}*/}
      {/*            value={{ sector: sector.id }}*/}
      {/*        />*/}
      {/*    ))}*/}
      {/*</FilterList>*/}

      {/*<FilterList*/}
      {/*    label="Account manager"*/}
      {/*    icon={<SupervisorAccountIcon />}*/}
      {/*>*/}
      {/*    <FilterListItem*/}
      {/*        label="Me"*/}
      {/*        value={{*/}
      {/*            sales_id: identity && identity.id,*/}
      {/*        }}*/}
      {/*    />*/}
      {/*</FilterList>*/}
    </Box>
  );
};
