import * as React from 'react';
import {
  List,
  TopToolbar,
  ExportButton,
  CreateButton,
  Pagination,
  useGetIdentity,
} from 'react-admin';

import { ImageList } from './GridList';
import { PartyListFilter } from './PartyListFilter';

export const PartyList = () => {
  const { identity } = useGetIdentity();
  if (!identity) return null;
  return (
    <List
      aside={<PartyListFilter />}
      pagination={<Pagination rowsPerPageOptions={[15, 25, 50, 100]} />}
      perPage={25}
      sort={{ field: 'createdAt', order: 'DESC' }}
      component="div"
    >
      <ImageList />
    </List>
  );
};

const PartyListActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
      <CreateButton
        variant="contained"
        label="New Party"
        sx={{ marginLeft: 2 }}
      />
    </TopToolbar>
  );
};
