import * as React from 'react';
import {
  TextField,
  DateField,
  FunctionField,
  ReferenceField,
  EditButton,
  ShowButton,
  useRecordContext, ReferenceManyField,
} from 'react-admin';
import { Box, Typography, Divider, Link } from '@mui/material';

import { Party, Sale, User } from '../types';

interface PartyAsideProps {
  link?: string;
}

export const PartyAside = ({ link = 'edit' }: PartyAsideProps) => {
  const record = useRecordContext<Party>();
  if (!record) return null;
  return (
    <Box sx={{ direction: 'rtl' }} ml={4} width={250} minWidth={250}>
      <Box mb={2} ml="-5px">
        {link === 'edit' ? (
          <EditButton label="עריכת אירוע" />
        ) : (
          <ShowButton label="צפייה באירוע" />
        )}
      </Box>

      <Typography variant="subtitle2">פרטי אירוע</Typography>
      <Divider />

      <Box mt={2}>
        <Typography variant="body2">
          {record.website && (
            <>
              {' '}
              <Link href={`https://www.invite-ai.com/${record.slug}`}>
                {`https://www.invite-ai.com/${record.slug}`}
              </Link>
            </>
          )}
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="span"
        >
          כתובת
        </Typography>
      </Box>

      <Box mt={1} mb={3}>
        <TextField source="address" />
      </Box>

      <Divider />

      <Box mt={1}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="span"
        >
          נוצר ב
        </Typography>{' '}
        <DateField
          source="createdAt"
          options={{ year: 'numeric', month: 'long', day: 'numeric' }}
          color="textSecondary"
        />
        <br />
        <Typography
          component="span"
          variant="body2"
          color="textSecondary"
        >
          נוצר ע״י
        </Typography>{' '}
        <TextField source={'creator_name'} />
      </Box>
    </Box>
  );
};
