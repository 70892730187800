import * as React from 'react';
import {
  ShowBase,
  TextField,
  ReferenceField,
  ReferenceManyField,
  useShowContext,
} from 'react-admin';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';

import { Avatar } from './Avatar';
import { UserAside } from './UserAside';
import { LogoField } from '../parties/LogoField';
import { User } from '../types';
import { dataProvider } from '../dataProvider';

export const UserShow = () => (
  <ShowBase>
    <UserShowContent />
  </ShowBase>
);

const UserShowContent = () => {
  const { record, isPending } = useShowContext<User>();
  if (isPending || !record) return null;


  const onUserLoginCodeCreate = async () => {
    const { data: { secret } } = await dataProvider.create('userLoginCode', {
      data: {
        user_id: record.id,
      },
    });

    alert(`הקוד הוא: ${secret} `);
  };

  return (
    <Box mt={2} display="flex">
      <Box flex="1">
        <Card>
          <CardContent>
            <Box display="flex">
              <Avatar />
              <Box ml={2} flex="1">
                <Typography variant="h5">
                  {record.first_name} {record.last_name}
                </Typography>
                <Typography variant="body2" component="div">
                  {record.title}
                </Typography>
              </Box>
            </Box>
            <Box className={'mt-5'}>
              <Typography variant={'h5'}>
                יצירת קוד התחברות
              </Typography>
              <Button onClick={onUserLoginCodeCreate} variant={'contained'}>
                יצירה
              </Button>
            </Box>

          </CardContent>
        </Card>
      </Box>
      <UserAside />
    </Box>
  );
};
