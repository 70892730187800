/* eslint-disable import/no-anonymous-default-export */
import { PartyList } from './PartyList';
import { PartyCreate } from './PartyCreate';
import { PartyShow } from './PartyShow';
import { PartyEdit } from './PartyEdit';

export default {
  list: PartyList,
  create: PartyCreate,
  edit: PartyEdit,
  show: PartyShow,
};
