import * as React from 'react';
import { useState, ChangeEvent } from 'react';
import {
  ShowBase,
  TextField,
  ReferenceManyField,
  SelectField,
  useShowContext,
  useRecordContext,
  useListContext,
  RecordContextProvider,
  SortButton,
} from 'react-admin';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Tabs,
  Tab,
  Divider,
  Stack,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link as RouterLink } from 'react-router-dom';
import { formatDistance } from 'date-fns';

import { Avatar } from '../users/Avatar';
import { Status, TicketStatus } from '../misc/Status';
import { PartiesList } from '../users/PartiesList';
import { sizes } from './sizes';
import { LogoField } from './LogoField';
import { PartyAside } from './PartyAside';
import { Party, Deal, User, Ticket } from '../types';
import { stageNames } from '../deals/stages';
import 'moment/locale/he';
// @ts-ignore
import moment from 'moment/min/moment-with-locales';
import ClockIcon from '@mui/icons-material/AccessTime';
import { dataProvider } from '../dataProvider';
import { extractDescription } from '../utils';

moment.locale('he'); // 'he' for Hebrew


export const PartyShow = () => (
  <ShowBase>
    <PartyShowContent />
  </ShowBase>
);

const PartyShowContent = () => {
  const { record, isPending, refetch } = useShowContext<Party>();
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  if (isPending || !record) return null;


  const updateStatus = async (status: string) => {
    await dataProvider.update('parties', {
      id: record.id,
      data: {
        status: status,
      }, previousData: record,
    });

    await refetch();
  }

  const approveParty = () => updateStatus('active');

  const endParty = () => updateStatus('done');


  return (
    <Box mt={2} display="flex">
      <Box flex="1">
        <Card>
          <CardContent>
            <Box display="flex" mb={1}>
              <LogoField />
              <Box ml={2} flex="1">
                <Typography variant="h5">
                  {record.title}
                </Typography>
                <Typography variant="body2">
                  {moment(record.date).format('DD/MM/YYYY HH:mm')} <ClockIcon sx={{ fontSize: 14 }} />
                </Typography>
                <Typography variant="body2">
                  {record.address}
                </Typography>
                <Typography className={'text-right pt-5'} variant="body2">
                  {extractDescription(record.description)}
                </Typography>
              </Box>
            </Box>
            {
              record.status == 'onhold' || record.status == 'pending' ? (
                <Box className={'mt-5'}>
                  <Button onClick={approveParty} variant={'contained'}>
                    אישור אירוע
                  </Button>
                </Box>
              ) : <></>
            }
            {
              record.status == 'active' ? (
                <Box className={'mt-5'}>
                  <Button color={'error'} onClick={endParty} variant={'contained'}>
                    סיום אירוע
                  </Button>
                </Box>
              ) : <></>
            }
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              {record.totalTickets && (
                <Tab
                  label={`כרטיסים (${record.totalTickets}) `}
                />
              )}
            </Tabs>
            <Divider />
            <TabPanel value={tabValue} index={0}>
              <ReferenceManyField
                reference="tickets"
                target="party_id"
                sort={{ field: 'id', order: 'DESC' }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  mt={1}
                >
                  <SortButton
                    fields={[
                      'last_name',
                      'first_name',
                      'last_seen',
                    ]}
                  />
                  {/*<CreateRelatedUserButton />*/}
                </Stack>
                <TicketsIterator />
              </ReferenceManyField>
            </TabPanel>
          </CardContent>
        </Card>
      </Box>
      <PartyAside />
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

const TicketsIterator = () => {
  const { data: tickets, error, isPending } = useListContext<Ticket>();
  if (isPending || error) return null;

  const now = Date.now();
  return (
    <List dense sx={{ pt: 0 }}>
      {tickets.map(ticket => (
        <RecordContextProvider key={ticket.id} value={ticket}>
          <ListItem
            button
            key={ticket.id}
            component={RouterLink}
            to={`/tickets/${ticket.id}/show`}
          >
            <ListItemAvatar>
              <Avatar />
            </ListItemAvatar>
            <ListItemText
              primary={`${ticket.first_name} ${ticket.last_name}`}
              secondary={
                <div dir={'rtl'}>
                  תז: {ticket.national_id} | פלאפון: {ticket.phone_number} | מייל:{ticket.email}
                </div>
              }
            />
            <ListItemSecondaryAction>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
              >
                נקנה{' '}
                {moment(ticket.created_at).fromNow()}
              </Typography>
              <br />

              {ticket.is_approved !== null ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span"
                >
                  {ticket.is_approved === 1 ? 'אושר' : 'נדחה'}{' '}
                  {moment(ticket.updated_at).fromNow()}
                  <TicketStatus status={ticket.is_approved} />
                </Typography>
              ) : <Typography
                variant="body2"
                color="textSecondary"
                component="span"
              >
                {'ממתין'}{' '}
                <TicketStatus status={ticket.is_approved} />
              </Typography>}

            </ListItemSecondaryAction>
          </ListItem>
        </RecordContextProvider>
      ))}
    </List>
  );
};

const CreateRelatedUserButton = () => {
  const party = useRecordContext<Party>();
  return (
    <Button
      component={RouterLink}
      to="/users/create"
      state={party ? { record: { party_id: party.id } } : undefined}
      color="primary"
      size="small"
      startIcon={<PersonAddIcon />}
    >
      Add user
    </Button>
  );
};

const DealsIterator = () => {
  const { data: deals, error, isPending } = useListContext<Deal>();
  if (isPending || error) return null;

  const now = Date.now();
  return (
    <Box>
      <List dense>
        {deals.map(deal => (
          <ListItem
            button
            key={deal.id}
            component={RouterLink}
            to={`/deals/${deal.id}/show`}
          >
            <ListItemText
              primary={deal.name}
              secondary={
                <>
                  {/* @ts-ignore */}
                  {stageNames[deal.stage]},{' '}
                  {deal.amount.toLocaleString('en-US', {
                    notation: 'compact',
                    style: 'currency',
                    currency: 'USD',
                    currencyDisplay: 'narrowSymbol',
                    minimumSignificantDigits: 3,
                  })}
                  , {deal.type}
                </>
              }
            />
            <ListItemSecondaryAction>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
              >
                last activity{' '}
                {formatDistance(deal.updated_at, now)} ago{' '}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
