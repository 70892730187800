import * as React from 'react';
import { Box, Paper } from '@mui/material';
import { RecordContextProvider, useListContext } from 'react-admin';

import { PartyCard } from './PartyCard';
import { Party } from '../types';

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = () => (
  <Box display="flex" flexWrap="wrap" width={1008} gap={1}>
    {times(15, key => (
      <Paper
        sx={{
          height: 200,
          width: 194,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'grey[200]',
        }}
        key={key}
      />
    ))}
  </Box>
);

const LoadedGridList = () => {
  const { data, error, isPending } = useListContext<Party>();

  if (isPending || error) return null;

  return (
    <Box display="flex" flexWrap="wrap" width="100%" gap={1}>
      {data.map(record => (
        <RecordContextProvider key={record.id} value={record}>
          <PartyCard />
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export const ImageList = () => {
  const { isPending } = useListContext();
  return isPending ? <LoadingGridList /> : <LoadedGridList />;
};
