import {
  Admin,
  ListGuesser,
  Resource,
  defaultTheme,
  localStorageStore,
} from 'react-admin';

import Layout from './Layout';
import { authProvider } from './authProvider';
import parties from './parties';
import users from './users';
import { Dashboard } from './dashboard/Dashboard';
import { dataProvider } from './dataProvider';
import deals from './deals';
import './index.css';

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    store={localStorageStore(undefined, 'CRM')}
    layout={Layout}
    theme={{
      ...defaultTheme,
      palette: {
        background: {
          default: '#fafafb',
        },
      },
    }}
  >
    <Resource name="parties" {...parties} />
    <Resource name="deals" {...deals} />
    <Resource name="users" {...users} />
    <Resource name={'tickets'} />
    <Resource name="userNotes" />
    <Resource name="dealNotes" />
    <Resource name="tasks" list={ListGuesser} />
    <Resource
      name="sales"
      list={ListGuesser}
      recordRepresentation={(record: any) =>
        `${record.first_name} ${record.last_name}`
      }
    />
    <Resource name="tags" list={ListGuesser} />
  </Admin>
);

export default App;
